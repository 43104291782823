  
  
  .BusinessPlan .advantages-section ul {
    line-height: 1.5;
    list-style-type: none;
    padding-left: 0;
    font-size: 18px;
    color: #333;
  }
  .advantages-section ul li {
    margin-bottom: 1rem;
  }

  .advantages-section ul li img{
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  .BusinessPlan .value-item img {
    max-width: 80px;
    height: auto;
  }
  
  .BusinessPlan .cta-section h2 {
    color: #fff;
  }
  
  .BusinessPlan .cta-section p {
    color: #fff;
    margin-bottom: 2rem;
  }
  
  .BusinessPlan .cta-section .btn-light {
    color: #002b5b;
    font-weight: bold;
    padding: 0.75rem 2rem;
  }
  
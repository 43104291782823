.banner-section {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 300px;
    background-size: cover;
    background-position: center;
    padding-left: 50px;
    color: #2c3e50;
  }
  

  .banner-category {
    line-height: 0;
    font-size: 16px; 
    color: #CAAB82; 
    font-weight: 400;
  }
  
  .banner-title {
    line-height: 1.3;
    width: 55%;
    margin: 15px 0 15px 0;
    color: #1A2947;
  }
  
  .banner-subtitle {
    width: 50%;
    font-size: 18px;
    line-height: 1.5;
    color: #666; 
  }
  .value-item p{
    font-size: 16px;
  }
  @media (max-width: 767px) {
    .banner-section{
      text-align: center;
      padding: 0;
    }
    .banner-title{
      width: 100%;
    }
    .banner-subtitle{
      display: none;
    }
  }
.LeadingBrand {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .LeadingBrand .card{
    border: 0;
  }
  .LeadingBrand .card-footer{
    margin-bottom: 30px;
    border-width:0;
  }
  .content-wrapper {
    flex: 1;
  }
  
  .topest-section {
    background-size: cover;
    background-position: center;
    padding:100px 0 140px 0;
    position: relative;
    z-index: 1;
    margin-bottom: 0; 
  }

  .topest-section h1{
  color:#CAAB82;
  }

  .topest-section p{
    font-size: 16px;
    color: #777777;
  }
  
  .brands-section {
    padding: 20px 0;
    margin-top: -120px; 
    position: relative;
    z-index: 2; 
  }
  
  .LeadingBrand .card-body{
    padding: 14px 0 10px 0;
  }
.LeadingBrand a.custom-button-bottom {
    text-decoration: none;
    border-radius: 50px;
    border: #1b2a41;
    background-color: #1b2a41;
    color: #ffffff;
    padding: 15px 25px;
    font-size: 14px;
}

.LeadingBrand a.custom-button-bottom:hover{
    background-color: #CAAB82; 
    color: #fff;
}
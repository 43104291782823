.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip-content {
    font-size: 12px;
    position: absolute;
    bottom: 120%; 
    left: 83%;
    transform: translateX(-83%);
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 11111;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .tooltip-container:hover .tooltip-content {
    opacity: 1;
    visibility: visible;
  }
  
  .info-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d1d5db; 
    color: #000;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
  }
  .tooltip-content ul {
   
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .tooltip-content li {
    color:#fff;
    margin-bottom: 5px;
  }
  
.pricing-section .nav-pills {
    border-bottom: 1px solid #ddd;
}

.pricing-section .nav-pills .nav-link {
    border: none;
    color: #999;
    font-weight: 500;
    padding: 10px 15px;
    margin-right: 15px;
    border-radius: 0; 
    transition: color 0.3s ease, background-color 0.3s ease;
}

.pricing-section .nav-pills .nav-link.active {
    color: #1A2947;
    background-color: transparent; 
    border-bottom: 3px solid #1A2947;
}

.pricing-section .nav-pills .nav-link:hover {
    color: #1A2947;
    background-color: transparent;
}

.pricing-section .nav-pills .nav-item {
    margin-bottom: -1px; 
}
  
.pricing-section .pricing-section .card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .pricing-section .pricing-section .card:hover {
    transform: translateY(-10px);
  }
  
 
  
  .pricing-section .pricing-section .card-title {
    font-size: 1.5rem;
    color: #333;

  }
  
  .pricing-section .pricing-section .card-price {
    font-size: 2.5rem;
    color: #1A2947;

  }
  
  .pricing-section .pricing-section .card-description {
    font-size: 1rem;
    color: #666;
  }
  
  .pricing-section .pricing-section .btn-outline-primary {
    background-color: #CAAB82;
    color: #fff;
    border-radius: 30px;
    padding: 10px 30px;
    margin-top: 15px;
    transition: background-color 0.3s ease;
  }
  
  .pricing-section .pricing-section .btn-outline-primary:hover {
    background-color: #A88A6B;
    color: #fff;
  }
  .pricing-card .list-unstyled li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #333;
  }
  
  .pricing-card .list-unstyled li .icon {
    margin-right: 10px;
    font-size: 1.2rem;
  }
  
  .pricing-card .list-unstyled li .icon-green {
    color: #c5a47e; 
  }
  
  .pricing-card .list-unstyled li .icon-red {
    color: #d9534f;
  }
  
  @media (max-width: 768px) {
    .pricing-section .pricing-section .card {
      margin-bottom: 30px;
    }
  }
  
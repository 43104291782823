.theme-design .section-title {
  color: #1A2947;
  font-size: 2rem;
  margin-bottom: 15px;
}

.theme-design .theme-preview .theme-name {
  font-size: 1.5rem;
  color: #1A2947;
  margin-bottom: 10px;
}

.theme-design .theme-preview .theme-description {
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
}

.theme-design .badge.new {
  font-size: 14px;
  background-color: #CAAB82;
  color: white;
  margin-right: 10px;
}

.theme-gallery .theme-item {
  text-align: center;
}

.theme-gallery .theme-item img {
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.theme-gallery .theme-item .theme-name {
  font-size: 1.25rem;
  color: #1A2947;
  margin-top: 10px;
}

.price {
  color: #1A2B52;
  font-weight: bold;
}
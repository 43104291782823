.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-weight: 400;
  font-size: 16px; 
  line-height: 1.2;
}

html, body {

  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Noto Sans TC", sans-serif;
}
.content-big-wrap {
  flex: 1;
}


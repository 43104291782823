.full-width-section {
    width: 100%;
    margin: 0 auto;
    padding: 80px 20px;
    background-color: #F8F8F8;
    text-align: center; 
  }
  
  .full-width-title {
    font-size: 2rem;
    color: #1A2947;
  }
  
  .full-width-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .full-width-content h2 {
    font-size: 1.5rem;
    color: #1A2947;
    margin-bottom: 10px;
  }
  
  .full-width-content p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .full-width-footer {
    text-align: center;
    margin-top: 40px;
    font-size: 0.9rem;
  }
  
  .full-width-footer p {
    margin: 5px 0;
  }
  
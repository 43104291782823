.header {
    color: #fff;
    transition: all 0.5s;
    z-index: 997;
    background-color: #1A2947;
}

.header .topbar {
    background-color: #fff;
    height: 40px;
    padding: 0;
    font-size: 16px;
    transition: all 0.5s;
    color: #000;
}

.topbar-item {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.topbar-item i {
    margin-right: 4px;
}

.topbar-divider {
    border-left: 1px solid #000;
    height: 20px;
    margin-right: 16px;
}


.header .branding {
    background: #1A2947;
    min-height: 60px;
    padding: 10px 0;
}

.header .logo {
    line-height: 1;
}

.header .logo h1 {
    font-size: 30px;
    margin: 0;
}



@media (max-width: 1200px) {
    .header .logo {
        order: 1;
    }

    .header .cta-btn {
        order: 2;
        margin: 0 15px 0 0;
        padding: 6px 15px;
    }

    .header .navmenu {
        order: 3;
    }
}

.scrolled .header {
    box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

.scrolled .header .topbar {
    height: 0;
    visibility: hidden;
    overflow: hidden;
}

@media (min-width: 1200px) {
    .navmenu ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navmenu li {
        position: relative;
    }

    .navmenu>ul>li {

        white-space: nowrap;
        padding: 15px 14px;
    }

    .navmenu .dropdown-item,
    .navmenu a,
    .navmenu a:focus {
        color: #fff;
        font-size: 15px;
        padding: 0 2px;
        text-decoration: unset;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        transition: 0.3s;
        position: relative;
    }

    .navmenu .dropdown-item i,
    .navmenu a i,
    .navmenu a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
        transition: 0.3s;
    }

    .navmenu>ul>li>a:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -6px;
        left: 0;
        background-color: #CAAB82;
        visibility: hidden;
        width: 0px;
        transition: all 0.3s ease-in-out 0s;
    }

    .navmenu a:hover:before,
    .navmenu li:hover>a:before,
    .navmenu .active:before {
        visibility: visible;
        width: 100%;
    }

    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
        color: #CAAB82;
    }

    .navmenu .dropdown ul {
        margin: 0;
        padding: 10px 0;
        background: #1A2947;
        display: block;
        position: absolute;
        visibility: hidden;
        left: 14px;
        top: 130%;
        opacity: 0;
        transition: 0.3s;
        border-radius: 4px;
        z-index: 99;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }

    .navmenu .dropdown ul li {
        min-width: 200px;
    }

    .navmenu .dropdown ul a, .navmenu .dropdown ul .dropdown-item {
        padding: 13px 20px;
        font-size: 15px;
        text-transform: none;
        color: #fff;
    }

    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
        color: #CAAB82;
    }

    .navmenu .dropdown:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navmenu .dropdown .dropdown ul {
        top: 0;
        left: 90%;
        visibility: hidden;
    }

    .navmenu .dropdown .dropdown:hover>ul {
        opacity: 1;
        top: 0;
        left: 100%;
        visibility: visible;
    }
}

@media (max-width: 1199px) {
    .mobile-nav-toggle {
        color: #fff;
        font-size: 28px;
        line-height: 0;
        margin-right: 10px;
        cursor: pointer;
        transition: color 0.3s;
    }

    .navmenu ul {
        display: none;
        list-style: none;
        position: absolute;
        inset: 60px 20px 20px 20px;
        padding: 10px 0;
        margin: 0;
        border-radius: 6px;
        background-color: #1A2947;
        box-shadow: none;
        overflow-y: auto;
        transition: 0.3s;
        z-index: 9998;
    }

    .navmenu a,
    .navmenu a:focus {
        color: #fff;
        padding: 10px 20px;
        font-size: 17px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navmenu .dropdown ul {
        position: static;
        display: none;
        z-index: 99;
        padding: 10px 0;
        margin: 0 10px;
        background-color: rgba(33, 37, 41, 0.03);
        transition: all 0.5s ease-in-out;
    }

    .navmenu .dropdown>.dropdown-active {
        display: block;
        background-color: rgba(33, 37, 41, 0.03);
    }

    .mobile-nav-active {
        overflow: hidden;
    }

    .mobile-nav-active .mobile-nav-toggle {
        color: #fff;
        position: absolute;
        font-size: 30px;
        top: 15px;
        right: 15px;
        margin-right: 0;
        z-index: 9999;
    }

    .mobile-nav-active .navmenu {
        position: fixed;
        overflow: hidden;
        inset: 0;
        background: rgba(33, 37, 41, 0.8);
        transition: 0.3s;
    }

    .mobile-nav-active .navmenu>ul {
        display: block;
    }
}
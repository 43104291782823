.Footer {
  background-color: #192a56; 
  color: #ffffff; 
}

.Footer h5 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.Footer ul {
  padding-left: 0;
}

.Footer ul li {
  font-size: 14px;
  color: #ffffff;
  list-style: none;
  margin-bottom: 0.8rem;
}

.Footer ul li a {
  color: #ffffff;
  text-decoration: none;
}

.Footer ul li a:hover {
  color: #CAAB82; 
  text-decoration: underline;
}

.Footer .social-icons a {
  color: #ffffff;
  font-size: 1.5rem;
  margin-right: 15px;
}

.Footer .social-icons a:hover {
  color: #CAAB82; 
}

.Footer p {
  font-size: 14px;
  margin-right: 3rem;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.Footer p a {
  color: #ffffff;
  text-decoration: none;
}

.Footer p a:hover {
  text-decoration: underline;
}

.BrandServices {
    background-color: #f9f9f9; 
    text-align: center;
  }
  .service-item img {
    max-width: 50px; 
    height: auto;
    margin: 0 auto;
  }
  
  .service-item p {
    margin-top: 0.5rem;
    font-size: 16px;
    color: #666;
  }
  
.ProductUpdates {
  padding-bottom: 30px;
}

.articles-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.article-item {
  display: flex;
  align-items: flex-start;  
  border-top: 1px solid #B8B8B8;
  border-radius: 5px;
  padding: 20px;
  transition: box-shadow 0.3s ease;
}


.article-date {
  width: 60px; 
  height: 60px; 

  background-color: #1a2947;
  color: #CAAB82; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  border-radius: 3px;
  margin-right: 15px;
}

.article-image {
  display: flex;
  align-items: flex-start;
}

.article-image img {
  max-width: 200px;
  border-radius: 5px;
  object-fit: cover;
}

.article-content {
  flex: 1;
  padding-left: 20px;
}

.article-content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.article-content p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.article-content .btn-link {
  color: #CAAB82;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.article-content .btn-link:hover {
  color: #cc9851;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .article-item {
    flex-direction: column;
  }

  .article-image img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .article-content {
    padding-left: 0;
  }
}


.background-section {
    background-image: url('../assets/MicroBusinessSolutions/01.png');
    background-color: #1A2947;
    background-repeat: no-repeat;
    color: white; 
    position: relative;
    border-radius: 10px;
  }
  .background-section .container {
    position: relative;
    z-index: 2;
  }
  
  .background-section h2,
  .background-section p {
    color:#fff;
    z-index: 2;
    position: relative;
  }
  
  .background-section .btn-outline-light {
    border-color: white;
    color: white;
  }
  
  .background-section .btn-outline-light:hover {
    background-color: white;
    color: #1A2947; 
  }
  
  .image-background {
    background-size: cover;
    background-position: center;
    min-height: 300px;
    border-radius: 10px 0 0 10px; 
  }
  
  @media (max-width: 991px) {
    .background-section {
      padding: 40px 20px;
    }
  
    .background-section h1 {
      font-size: 2rem;
    }
  
    .background-section .btn-lg {
      width: 100%;
    }
  
    .image-background {
      border-radius: 10px 10px 0 0;
      min-height: 200px;
    }
  }
  
.lead{
    font-weight: 400;
}
  
  .feature-overview-section h3 {
    font-size: 1.5rem;
    color: #CAAB82; 
  }
  
  .feature-overview-section h2 {
    font-size: 2rem;

  }

  
  .feature-overview-section .img-fluid {
    border-radius: 8px; 
  }
  
  .feature-overview-section .text-warning {
    color: #CAAB82 !important;
    margin-bottom: 0;
  }
  
  @media (max-width: 991px) {
    .feature-overview-section {
      padding: 20px 0;
    }
  
    .feature-overview-section .row.align-items-center {
      flex-direction: column;
      text-align: center;
    }
  
    .feature-overview-section .col-md-5 {
      margin-bottom: 20px;
    }
  }
  .single-page-store-section {
    background-color: #f8f9fa; 
  }
  
  .single-page-store-section h3 {
    margin-bottom: 0;
    font-size: 1.5rem;
    color: #CAAB82 !important;
  }
  
  .single-page-store-section h2 {
    font-size: 2rem;
    color: #333;
  }
  
  .single-page-store-section p {
    font-weight: 400;
    font-size: 1rem;
    color: #666;
  }
  
  .single-page-store-section .steps-list {
    padding-left: 0;
    list-style: none;
    margin-top: 20px;
  }
  .single-page-store-section .blue{
    color: #1A2947;
    line-height: 0;
  }
  .single-page-store-section .steps-list li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .single-page-store-section .steps-list li strong {
    color: #1A2947;
  }
  
  .single-page-store-section .img-fluid {
    border-radius: 8px;
  }
  
  @media (max-width: 991px) {
    .single-page-store-section {
      padding: 20px 0;
    }
  
    .single-page-store-section .row.align-items-center {
      flex-direction: column;
      text-align: center;
    }
  
    .single-page-store-section .col-md-5 {
      margin-bottom: 20px;
    }
  }
  .order-management-section {
    background-color: #f8f9fa; 
  }
  
  .order-management-section h3 {
    margin-bottom: 0!important;
    font-size: 1.5rem;
    color: #CAAB82 !important;
  }
  
  .order-management-section h2 {
    font-size: 2rem;
    color: #333;
  }
  
  .order-management-section p {
    font-size: 1rem;
    color: #666;
  }
  
  .order-management-section .features-list {
    margin-top: 20px;
  }
  
  .order-management-section .features-list ul {
    padding-left: 0;
  }
  
  .order-management-section .features-list li {
    margin-bottom: 10px;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  .order-management-section .features-list img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
  .order-management-section .img-fluid {
    border-radius: 8px;
  }
  
  @media (max-width: 991px) {
    .order-management-section {
      padding: 20px 0;
    }
  
    .order-management-section .row.align-items-center {
      flex-direction: column;
      text-align: center;
    }
  
    .order-management-section .col-md-6 {
      margin-bottom: 20px;
    }
  
    .order-management-section .features-list {
      text-align: left;
    }
  }
  
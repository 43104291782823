.privacy-policy-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .privacy-policy-title {
    font-size: 2rem;
    text-align: center;
    color: #1A2947;
    margin-bottom: 30px;
  }
  
  .privacy-policy-content section {
    margin-bottom: 22px;
    border-bottom: 1px solid #C6C6C6;
  }
  
  .privacy-policy-content h4 {
    color: #1A2947;
    margin-bottom: 10px;
  }
  
  .privacy-policy-content p {
    font-size: 16px;
    line-height: 1.5;
   
  }
  
  .privacy-policy-footer {
    text-align: left;
    margin-top: 40px;
    font-size:12px;
 
  }
  
  .privacy-policy-footer p {
    margin: 5px 0;
  }
  
.toggleWrapper {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 50px;
  background-color: #e0e0e0;
  border-radius: 25px;
  overflow: hidden;
}

.toggleWrapper input {
  position: absolute;
  left: -9999px;
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toggle__handler {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 3px;
  width: 150px;
  height: 44px;
  background-color: #c5a47e;
  border-radius: 22px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.toggle__label--off,
.toggle__label--on {
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: color 0.3s ease;
}

.toggle__label--off {
  left: 40px;
  color: #fff;
}

.toggle__label--on {
  right: 40px;
  color: #333;
}

input:checked+.toggle .toggle__handler {
  transform: translateX(150px);
  background-color: #c5a47e;
  border-radius: 22px;
}

input:checked+.toggle .toggle__label--off {
  color: #333;
}

input:checked+.toggle .toggle__label--on {
  color: #fff;

}

.pricing-switcher {
  margin-top: 20px;
}

.pricing-card {
  position: relative;
  z-index: 1;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.pricing-card .card-header.recommended {
  background-color: #2c2c6e;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  color: #fff;
}

.pricing-card .badge {
  font-size: 1rem;
  font-weight: bold;
}

.pricing-card .card-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #1A2947;
  text-align: center;
  margin-top: 20px;
}

.pricing-card.recommended .card-title {
  color: #fff;
}

.pricing-card .card-price {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
}

.pricing-card.recommended .card-price {
  color: #fff;
}

.pricing-card .card-price small {
  font-size: 1rem;
  color: #666;
}

.pricing-card.recommended .card-price small {
  color: #ddd;
}

.pricing-card .card-description {
  font-size: 20px;
  color: #1A2947;
  text-align: center;

}

.pricing-card.recommended .card-description {
  color: #ddd;
}

.pricing-card .btn-primary {
  width: 100%;
  padding: 10px 0;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #c5a47e;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pricing-card .btn-primary a {
  color: #fff;
}

.pricing-card.recommended .btn-primary {
  background-color: #fff;
  color: #2c2c6e;
}

.pricing-card .btn-primary:hover {
  background-color: #a6865c;
}

.pricing-card.recommended .btn-primary:hover {
  background-color: #e0e0e0;
}

.pricing-card .list-unstyled {
  margin-top: 20px;
}

.pricing-card .list-unstyled li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
}

.pricing-card .icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.pricing-card .icon-green {
  color: #c5a47e;
}

.pricing-card .icon-red {
  color: #d9534f;
}

.currency {
  font-size: 1.2rem;
  margin-right: 5px;
}

.price {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-size: 46px;
  margin-right: 5px;
}

.info-icon {
  font-size: 1rem;
  margin-left: 5px;
  color: #fff;
}

.card-original-price {
  font-size: 1rem;
  color: #c5a47e;
  margin-top: 10px;
  margin-bottom: 0;
}

.card-original-price span {
  font-size: 32px;
  font-family: "Bebas Neue", sans-serif;
  text-decoration: line-through;
  color: #c5a47e;
}

.card-annual-price {
  font-size: 1rem;
  color: #c5a47e;
  margin-top: 10px;
  margin-bottom: 0;
}

.card-annual-price span {
  font-size: 32px;
  font-family: "Bebas Neue", sans-serif;
  color: #c5a47e;
}

.card-price-container {
  font-size: 2rem;
  font-weight: bold;
  color: #1A2B52;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 10px;
  position: relative;
}

.info-icon {
  position: relative;
  cursor: pointer;
}

.tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
  padding: 8px;
  background-color: #333;
  color: #fff;
  font-size: 0.8rem;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
}

.info-icon:hover .tooltip {
  display: block;
}
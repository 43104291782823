.contact {

  padding-bottom: 40px;
}

.contact .service-hours {
  text-align: center;
  margin: 40px 0;
  font-size: 1.1rem;
  color: #666;
}

.contact .service-hours p {
  margin-bottom: 6px;
}
.contact .contact-form-section {
  background-color: #fff;
  padding: 80px; 
  margin: 20px auto;
  max-width: 620px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.contact .contact-form-section h2 {
  margin-bottom: 15px; 
  font-size: 1.5rem;
  color: #333;
}

.contact .contact-form input, .contact .contact-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.contact .contact-form input:focus, .contact .contact-form textarea:focus {
  border-color: #CAAB82;
  outline: none;
}

.contact .submit-button {
  background-color: #CAAB82;
  color: white;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact .card-title {
  font-weight: 600;
}

.contact .card-text a {
  font-size: 16px;
  color: #a88a6b;
}

.contact p.card-text {
  margin-bottom: 10px;
}

.contact .submit-button:hover {
  background-color: #a88a6b;
}

.contact .card-square {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding-top: 300px;
  position: relative;
}

.contact .card-body {
  border-radius: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.contact .text-container a {
  color: #a88a6b;
}
.contact .text-container small{
  font-size: 16px;
  color: #a88a6b !important;
}
.contact .icon-container {
  background-color: #CAAB82;
  border-radius: 8px;
  padding: 10px;
}

@media (max-width: 767px) {
  .contact .card-square {
    max-width: 100%; 
    width: 100%;
    margin-left: 15px;  
    margin-right: 15px; 
  }

  .contact .contact-form-section {
    max-width: 80%;
    padding:30px 20px;
   
  }

  .contact .contact-form input {
    padding: 8px;
    margin-bottom: 15px;
  }

  .contact .submit-button {
    padding: 10px 20px;
  }

  .card-body {
    padding: 15px; 
  }

  .icon-container {
    margin-bottom: 10px; 
  }
}


  .Partnership .advantage-item img {
    max-width: 60px;
    height: auto;
  }
  
  .Partnership .advantage-item p, .partner-item p {
    font-size: 15px;
    color: #777;
    margin-bottom: 0;
  }
  
  .Partnership .partners-section {
    padding: 3rem 0;
  }
  
  .Partnership .partners-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .Partnership .partner-item img {
    max-width: 300px;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .Partnership .advantage-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }


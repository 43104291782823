.SecondMainSection {
  background-image:url(../assets/bg-map.png) ;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 80%;
}
  .SecondMainSection .background-image {
    position: absolute;
    top: 180px;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  
  .SecondMainSection .text-section h2 {
    color: #0f294d;
    font-size: 2rem;
  }
  
  .SecondMainSection .text-section .main-title {
    color: #CAAB82;
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .SecondMainSection .text-section .highlight {
    color: #000;
  }
  
  .SecondMainSection .text-section p {
    line-height: 1.5;
    color: #333;
    font-size: 2.3rem;
  }

  @media (max-width: 768px) {
    .SecondMainSection .text-section h2 {
      color: #0f294d;
      font-size: 24px;
  }
    .SecondMainSection .text-section .main-title{
      font-size: 26px;
    }
    .SecondMainSection .text-section p {
      line-height: 1.5;
      color: #333;
      font-size: 26px;
    }
  
}
.LatestCases {
  background-color: #f8f9fa;
}

.LatestCases .card {
  border: none;
  transition: transform 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: rgb(246, 249, 252);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 15px 1px;
  border-radius: 8px;
  overflow: hidden;
}
.LatestCases a{
  color: #fff;
}
.LatestCases .card:hover {
  transform: translateY(-5px);
}

.LatestCases .card-img-top-wrapper {
  position: relative;
  overflow: hidden;
}

.LatestCases .card-logo-wrapper {
  position: absolute;
  top: 55%;
  right: 8.5%;
  transform: translateY(-55%);
  width: 5vw;
  height: 5vw;
  min-width: 104px;
  min-height: 104px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 6px;

}

.LatestCases .card-logo {
  max-width: 100%;
  height: auto;
}

.LatestCases .card-body {
  padding: 2rem 1rem;
  background-color: #1A2947;
  color: #fff;
}

.LatestCases .card-img-top {
  border-bottom: 1px solid #f1f1f1;
}

.LatestCases .card-text {
  color: #fff;
}

.LatestCases .custom-button-bottom {
  font-weight: 400;
  text-decoration: none;
  border-radius: 50px;
  border: #1b2a41;
  background-color: #1b2a41;
  color: #ffffff;
  padding: 18px 28px;
  font-size: 16px;
  display: inline-block;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.LatestCases .custom-button-bottom:hover {
  background-color: #CAAB82;
  color: #fff;
  border: #CAAB82;
}

@media (max-width: 768px) {
  .LatestCases .card-body {
    padding: 1rem 0.5rem;
  }
  
  .LatestCases .custom-button-bottom {
    font-size: 14px;
    padding: 12px 20px;
  }
}

.gold{
    color:#CAAB82;
}

.AboutUs .h4{
    color: #1A2947;
    font-weight: bold ;
}

  .solution-title {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .solution-description {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .solution-graphic img {
    max-width: 100%;
    height: auto;
  }
  
  .solution-label {
    font-size: 0.875rem;
    text-align: center;

    margin-top: 10px;
  }
  .custom-solution-section {
    background-color: #f2f2f2;
  }
 .talk-title {
  font-family: "Playwrite CU", cursive;
  
 }
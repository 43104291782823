
.LeftSliderSection .section-number{
    color: #1A2947;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-right: 10px;
    margin-top: -36px;
    font-size: 82px;
}
.LeftSliderSection .carousel-inner {
    padding: 0 40px;
}
  
.LeftSliderSection .section-content {
    max-width: 100%;
  }
  
  .LeftSliderSection .section-title {
    line-height: 1.2;
    font-size: 16px;
    font-weight: bold;
    color: #1b2a41; 
    margin: 0;
  }
  
  .LeftSliderSection .section-subtitle {
    font-size: 26px;
    color: #1b2a41; 
    margin-bottom: 20px;
  }
  
  .LeftSliderSection .solution-list {
    line-height: 1.5;
    list-style-type: none;
    padding:0 40px;
  }
  
  .LeftSliderSection .solution-list li {
    font-size: 16px;
    line-height: 1.8;
    margin: 10px 0;
    display: flex;
    align-items: center;
    
  }
  .LeftSliderSection .solution-section {
    display: flex;
    align-items: flex-start;
    padding:20px 20px 0 20px;
  }
  .icon-link {
    margin-right: 10px;
    width: 20px;
    height: 20px; 
    background-image: url('../assets/icon/list.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .LeftSliderSection .learn-more-button {
    display: inline-block;
    margin-top: 20px;
    background-color: #1b2a41; 
    color: #ffffff;
    border: none; 
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 50px;
    padding: 18px 28px;
    font-size: 16px;
    margin-left: 40px;
  }
  
  .learn-more-button:hover {
    background-color: #CAAB82;
    color: fff; 

  }
  .number-and-content {
    display: flex;
    align-items: center;
  }
  
  .text-content {
    display: flex;
    flex-direction: column;
  }
  
  .section-title {
    font-size: 1.5rem;
    margin: 0;
    color: #1F2937;
  }
  
  .section-subtitle {
    font-size: 1.8rem;
    margin: 0;
    color: #4B5563;
  }
  .LeftSliderSection .carousel-control-prev-icon,
  .LeftSliderSection .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.LeftSliderSection .carousel-control-prev,
.LeftSliderSection .carousel-control-next {
  z-index: 1;
}

.LeftSliderSection .carousel-indicators [data-bs-target] {
    width: 20px;
    height: 20px;
    margin: 10px;
    border-radius: 50%;
    background-color: #DCDCDC;
}
.LeftSliderSection .carousel-indicators {
    justify-content: end;
    top: -30px;
    margin-right: 5%;
}
.LeftSliderSection .carousel-indicators li {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
}

.LeftSliderSection .carousel-indicators .active {
    background-color: rgba(0, 0, 0, 0.9);
}
.LeftSliderSection .carousel-indicators li::marker {
    content: "";
  }

  @media (max-width: 992px) {
    .LeftSliderSection .section-number{
      font-size: 50px;
      margin-top: 0;
    }
    .LeftSliderSection .section-title, .LeftSliderSection .section-subtitle {
      font-size: 24px;
    }
    .LeftSliderSection .solution-list li {
      font-size: 16px;
    }
    .LeftSliderSection .learn-more-button {
      font-size: 16px;
    }
    .LeftSliderSection .carousel-inner {
      padding: 0 30px;
  }
    
}
@media (max-width: 414px) {
  .LeftSliderSection .section-number{
    font-size: 50px;
    margin-top: 0;
  }
  .LeftSliderSection .section-title, .LeftSliderSection .section-subtitle {
    font-size: 16px;
  }
  .LeftSliderSection .solution-list li {
    font-size: 18px;
  }
  .LeftSliderSection .learn-more-button {
    font-size: 16px;
  }
  .LeftSliderSection .carousel-inner {
    padding: 0 20px;
}
  
}
.banner-section-dark {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 300px;
    background-size: cover;
    background-position: center;
    padding-left: 50px;
    color: #2c3e50;
  }
  

  .banner-section-dark .banner-category {
    font-size: 1rem; 
    color: #CAAB82; 
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  .banner-section-dark .banner-title {
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #fff;
  }
  
  .banner-section-dark .banner-subtitle {
    width: 60%;
    font-size: 18px;
    line-height: 1.5;
    color: #fff; 
  }
  .banner-section-dark .value-item p{
    font-size: 16px;
  }
  @media (max-width: 767px) {
    .banner-section-dark{
      text-align: center;
      padding: 0;
    }
    .banner-title{
      width: 100%;
    }
    .banner-subtitle{
      display: none;
    }
  }
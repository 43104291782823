.NewsHighlights {
  padding-bottom: 30px;
}

.articles-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.article-item {
  border-top: 1px solid #B8B8B8;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  transition: box-shadow 0.3s ease;
}


.article-image img {
  max-width: 200px;
  border-radius: 5px;
  object-fit: cover;
}

.article-content {
  flex: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.article-content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.article-content p {
  font-size: 1rem;
  margin-bottom: 15px;
  flex-grow: 1;  
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.article-content .btn-link {
  font-size: 14px;
  color: #CAAB82;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
  align-self: flex-end; 
}

.article-content .btn-link:hover {
  color: #cc9851;
  text-decoration: underline;
}



@media (max-width: 768px) {
  .article-item {
    flex-direction: column;
  }

  .article-image img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .article-content {
    padding-left: 0;
  }
}


.MicroBusinessSolutionsStore .nav-pills {
  flex-direction: column;
  width: 100%;
}

.MicroBusinessSolutionsStore .nav-pills .nav-link {
  color: #333;
  border-radius: 0;
  padding: 10px 20px;
  margin-bottom: 5px;
  border-left: 4px solid transparent;
  transition: background-color 0.3s ease;
  text-align: left;
}

.MicroBusinessSolutionsStore .nav-pills .nav-link:hover {
  background-color: #1A2947;;
  color: #fff;
}

.MicroBusinessSolutionsStore .nav-pills .nav-link.active {
  background-color: #eaeaea;
  color: #1A2947;
  border-left: 4px solid #1A2947;;
}

.MicroBusinessSolutionsStore .tab-content {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.MicroBusinessSolutionsStore .tab-pane h3 {
  color: #1A2947;
  font-weight: bold;
}

.MicroBusinessSolutionsStore .tab-pane ul {
  padding-left: 20px;
  list-style-type: disc;
}

.MicroBusinessSolutionsStore .tab-pane ul li {
  margin-bottom: 10px;
  padding: 5px 0;
  color: #333;
  border-bottom: 1px solid #ddd;
}

.MicroBusinessSolutionsStore .tab-pane ul li:last-child {
  border-bottom: none;
}

.MicroBusinessSolutionsStore .tab-pane img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}


.MicroBusinessSolutionsStore h3 {
  font-size: 32px;
  font-weight: bold;
  color: #1A2947;
  margin-bottom: 10px;
}


.MicroBusinessSolutionsStore .img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
}

.MicroBusinessSolutionsStore .row {
  margin-bottom: 20px;
}

.MicroBusinessSolutionsStore .feature {
  margin-bottom: 20px;
}

.MicroBusinessSolutionsStore .feature h4 {
  font-size: 20px;
  font-weight: bold;
  color: #1A2947;
  margin-bottom: 10px;
}

.MicroBusinessSolutionsStore .feature p {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.MicroBusinessSolutionsStore .feature .icon {
  font-size: 24px;
  color: #1A2947;
  margin-right: 10px;
}

.MicroBusinessSolutionsStore .feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.MicroBusinessSolutionsStore .feature-list .feature-item {
  width: 48%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}


.resources-grid-section {
  padding: 50px 0;
}

.resources-grid-section .resource-item {
  border-radius: 0;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
}

.resources-grid-section .resource-item:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.resources-grid-section .card-img-top {
  border-radius: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.resources-grid-section .card-body {
  padding: 10px 10px 0 10px  ;
}

.resources-grid-section .card-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.resources-grid-section .card-footer {
  text-align: end;
  border-top: 0;
  padding: 0 10px 0 0;
  background-color: #fff;
}

.resources-grid-section .btn-link {
  color: #CAAB82; 
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}



.border-line{
  padding: 10px;
  border: 1px solid #e8e8e8;
}
.resource-item-link {
  text-decoration: none;
  color: inherit;

}
.resource-item-link:hover {
  text-decoration: none;
}

.pagination-section .pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  border-radius: 4px;
}

.pagination-section .page-item {
  margin: 0 5px;
}

.pagination-section .page-item .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #333;
  border: 1px solid #ddd;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-section .page-item.active .page-link {
  background-color: #CAAB82;
  border-color: #CAAB82;
  color: white;
}

.pagination-section .page-item.disabled .page-link {
  background-color: #f0f0f0;
  color: #a0a0a0;
  pointer-events: none;
}

.pagination-section .page-item .page-link:hover {
  background-color: #f0f0f0;
}

.pagination-section .page-item .page-link:focus {
  outline: none;
}

.faq-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .faq-container h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .faq-container p {
    font-size: 1.1rem;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .faq-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd; 
  }
  
  .faq-tab {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 400;
    color: #888;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease;
  }
  
  .faq-tab:hover,
  .faq-tab.active {
    color: #192a56;
  }
  
  .faq-tab.active::after {
    content: "";
    position: absolute;
    bottom: -2px; 
    left: 0;
    right: 0;
    height: 3px;
    background-color: #192a56;
    border-radius: 2px; 
  }
  
  .faq-icon img {
    width: 20px; 
    height: auto;
    margin-left: 10px; 
  }
  @media (max-width: 767px) {
    .faq-tab {
      padding: 8px 10px;
      font-size: 14px;
    }
  }
  
  .faq-item {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #F8F8F8;
    border:0
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size:16px;
    font-weight: 600;
  }
  
  .faq-icon {
    transition: transform 0.3s ease;
  }
  

  .faq-answer {
    line-height: 1.5;
    margin-top: 10px;
    font-size: 16px;
    color: #777;
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .faq-answer.show {
    display: block;
    max-height: 1000px; 
  }
  .faq-answer a{
    color: #c69c6d;
  }
  .faq-answer a:hover{
    color: #192a56;
  }
.terms-content-container {
  padding: 20px;
  margin: 0 auto;
}

.terms-content {

  line-height: 1.8;

}

.terms-content ol {
  padding-left: 20px; 
}

.terms-content li {
  margin-bottom: 10px;
}

.terms-footer {
  margin-top: 20px;

}

.terms-footer p {
  margin: 5px 0;
}

.footer-info {
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.updated-date,
.copyright {
  margin: 0;
}

.important-note {
  background-color: #f2f2f2;
  padding: 10px;
  margin: 20px 0;
  border-left: 4px solid #ccc;
}

.important-note p {
  margin: 0;
}

.solution-links p {
  margin: 10px 0;

}

.solution-links a {
  text-decoration: none;

  text-decoration: underline;
}

.solution-links a:hover {
  text-decoration: underline;
  color: #c69c6d;
}

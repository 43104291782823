.news-detail {
    padding-top: 20px;
  }
  
  .post-header {
    margin-bottom: 20px;
  }
  

  .post-meta {
    color: #666666;
    font-size: 0.9rem;
  }
  
  .post-meta span {
    margin-right: 10px;
  }
  
  .post-image {
    margin: 30px 0;
  }
  
  .post-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .post-content {
    margin-top: 20px;
  }
  
  .post-content p{
    line-height: 1.8;
    font-size: 18px;
    margin-bottom: 25px;
  }
  .post-content ul li {
    line-height: 1.8;
    font-size: 18px;
  }
  .post-content a{
    color: #CAAB82;
  }
  .post-content a:hover{
    color: #1A2947;
  }
.CallToAction {
  background-color: #c69c6d;
  color: #ffffff; 
}

.CallToAction h2{
  font-size: 30px;
  color: #fff;
}
.CallToAction p {
  margin-bottom: 2rem;
  color: #fff;
  line-height: 1.5;
  font-size: 20px;
}

.CallToAction .btn-group a.btn {
  font-weight: 400;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #1A2947; 
  padding:18px 28px;
  background-color: #ffffff; 
  border: 1px solid transparent;
  font-size: 16px;
}

.CallToAction .btn-group a.btn:hover {
  background-color: #1A2947;
  color: #fff; 

}
@media (max-width: 768px) {
  .CallToAction h2{
    font-size: 24px; 

  }
  .CallToAction .btn-group a.btn{
    line-height: 1.5;
    font-size: 16px;
  }
}
.timeline-section {
    padding: 40px 0;
}

.timeline {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    flex-wrap: wrap;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    z-index: 1;
}

.timeline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
    width: 20%;
    margin-bottom: 20px; 
}

.timeline-item::before {
    content: '';
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    width: 3px;
    height: 158px;
    background-color: #1A2947;
    z-index: 1;
}

.timeline-item:nth-child(2n)::before {
    background-color: #D4A76B;
}

.timeline-date {
    position: absolute;
    width: 100%;
    top: 172px; 
    left: 50%;
    transform: translateX(-50%);
    background-color: #1A2947;
    color: #fff;
    padding: 10px 20px;
    font-weight: bold;
    z-index: 2;
}

.timeline-item:nth-child(2n) .timeline-date {
    background-color: #D4A76B;
}

.timeline-content.bottom {
    width: 200px;
    margin: 20px;
    z-index: 22;
    margin-top: 150px;
    border: 3px solid #1A2947; 
    padding: 30px 15px;
    border-radius: 10px; 
    background-color: #fff;
}

.timeline-item:nth-child(2n) .timeline-content.bottom {
    border-color: #D4A76B;
}

.timeline-logo {
    height: 112px;
    margin-bottom: 10px;
}

.timeline-content p {
    margin: 0;
    color: #333;
    font-size: 14px;
}


@media (max-width: 991px) {
    .timeline-content.bottom{
        margin-top: 0;
    }
   
    .timeline {
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
    }

    .timeline-item {
        width: 80%;
        margin-bottom: 70px;
    }
    .timeline-item:last-child{
        margin-bottom: 0;
    }
    .timeline::before {
        top: auto; 
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 4px; 
    }
    .timeline-item:nth-child(2n)::before{
        background-color: unset;
    }
    .timeline-item::before {
        background-color: unset;
    }
    
    .timeline-date {
        top: -60px;
    }
}


@media (max-width: 576px) {
    .timeline-item {
        width: 100%;
    }
}

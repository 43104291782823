* {
  font-weight: 500;
  letter-spacing: 0.6px;
  font-family: 'Noto Sans TC', sans-serif;
}

.h1,
h1 {
  font-size: 2.3rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.carousel-control-prev,
.carousel-control-next {
  z-index: 1;
}

.carousel-indicators [data-bs-target] {
  width: 20px;
  height: 20px;
  margin: 10px;
}

.carousel-indicators li {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-indicators .active {
  background-color: rgba(0, 0, 0, 0.9);
}

.carousel-indicators li::marker {
  content: "";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
}

.bg-gray {
  background-color: #F5F7FA;
}

p,
li {
  color: #666;
}

p {
  font-size: 1rem;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #282828;

}

h2 {
  font-size: 30px;
}


a {
  text-decoration: unset;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a.custom-button-bottom {
  display: inline-block;
  text-decoration: none;
  border-radius: 50px;
  border: #1b2a41;
  background-color: #1b2a41;
  color: #ffffff;
  padding: 18px 28px;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a.custom-button-bottom:hover,
a.custom-button-bottom-white:hover,
a.custom-button-bottom-outline:hover {
  background-color: #CAAB82;
  color: #fff;

}

a.custom-button-bottom-white {
  display: inline-block;
  text-decoration: none;
  border-radius: 50px;
  border: #ffffff;
  background-color: #ffffff;
  color: #1b2a41;
  padding: 18px 28px;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a.custom-button-bottom-outline {
  display: inline-block;
  text-decoration: none;
  border-radius: 50px;
  border: 1px solid #1b2a41;
  background-color: #ffffff;
  color: #1b2a41;
  padding: 18px 28px;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a:hover {
  cursor: pointer;
}

.container {
  padding-left: 60px;
  padding-right: 60px;
}

@media (max-width: 768px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .remove-padding-mobile {
    padding: 0 !important;
  }

  .h1,
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 26px;
  }

  a.custom-button-bottom {
    font-size: 16px;
  }
}